

import "./LiveUnit.css";
import { Grid } from "@mui/material";
import { PatientVitals } from "../../../interfaces/vitals/PatientVitals";
import { useTranslation } from "react-i18next";

// note: this does not seem to be used in the LiveWaveforms screen.

export const LiveUnitParameters = ({
	parms,
	availableParms,
	connectStatus,
	isVideoOn,
	eqpAlarmsEnabled
}: LiveParmsProps) => {
	console.log ("LiveUnitParameters parms:", availableParms)
	const params = ["HR", "SpO2", "Resp", "IBP1", "Temp"];
	//console.log ("rx params:", parms)
	if (isVideoOn) {
		return (
			<Grid container>
				{params.map(
					(param) =>
						availableParms.includes(param) && (
							<ParmDisplay
								key={param}
								parm_name={param}
								// @ts-ignore
								parm={parms[param]}
								connectStatus={connectStatus}
								isVideoOn={isVideoOn}
								eqpAlarmsEnabled={eqpAlarmsEnabled}
							/>
						)
				)}
			</Grid>
		);
	}

	return (
		<Grid item width={"100%"} container>
			{availableParms.includes("HR") && (
				<ParmDisplay
					parm_name="HR"
					parm={parms.HR}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
				/>
			)}

			{availableParms.includes("SpO2") && (
				<ParmDisplay
					parm_name="SpO2"
					parm={parms.SpO2}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
				/>
			)}

			{availableParms.includes("IBP1") && (
				<ParmDisplay
					parm_name="IBP1"
					parm={parms.IBP1}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
				/>
			)}

			{availableParms.includes("Resp") && (
				<ParmDisplay
					parm_name="Resp"
					parm={parms.Resp}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
				/>
			)}

			{availableParms.includes("Temp") && (
				<ParmDisplay
					parm_name="Temp"
					parm={parms.Temp}
					connectStatus={connectStatus}
					eqpAlarmsEnabled={eqpAlarmsEnabled}
				/>
			)}
		</Grid>
	);
}; // LiveUnitParameters






export interface LiveParmsProps {
	availableParms: string[];
	parms: PatientVitals;
	connectStatus: string;
	isVideoOn?: boolean;
	eqpAlarmsEnabled: boolean;
}

export interface LiveParmProps {
	parm: any;
	parm_name: string;
	connectStatus: string;
	isVideoOn?: boolean;
	eqpAlarmsEnabled: boolean;
}




export const ParmDisplay = ({
	parm,
	parm_name,
	connectStatus,
	isVideoOn,
	eqpAlarmsEnabled
}: LiveParmProps) => {
	//console.log (connectStatus)
	const { t } = useTranslation();

	// Different parameters have slightly different parameter structures. Deal with that here.
	var parm_unit = "-";
	var parm_val = "-";
	var active_alarm = false;

	if (!parm) {
		parm_unit = "";
		parm_val = "-";
	} else {
		//console.log ("Parse parm:", parm_name, parm, parm.length)
		switch (parm_name) {
			case "HR": {
				parm_unit = parm.unit;
				parm_val = parm.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "SpO2": {
				parm_unit = parm.sp ? parm.sp.unit : parm.unit;
				parm_val = parm.sp ? parm.sp.value : parm.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "Resp": {
				parm_unit = parm.rr.unit;
				parm_val = parm.rr.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "IBP1": {
				parm_unit = parm.systole.unit;
				parm_val = parm.systole.value;
				active_alarm = parm.active_alarm;
				break;
			}
			case "Temp": {
				parm_unit = parm.t1 ? parm.t1.unit : parm.unit;
				parm_val = parm.t1 ? parm.t1.value : parm.value;
				//var parm_num = +parm_val; // convert from string to Number
				//if (isNaN (parm_num)) {
				//	parm_num = -1
				//} else {
				//	parm_num = +parm_num.toFixed(1)
				//}
				//				if (isNaN(+parm_val)) {
				//					parm_val = parm.t1.value;
				//				} else {
				//					const parm_num = Number(parm.t1.value);
				//					parm_val = parm_num.toFixed(1);
				//				}
				//parm_val = parm_val.toFixed (1)
				active_alarm = parm.active_alarm;
				break;
			}
		}
		active_alarm = active_alarm && eqpAlarmsEnabled
	}

	if (isVideoOn) {
		return (
			<Grid
				container
				item
				xs={3}
				sx={{
					padding: 0.5,
					backgroundColor: "black",
					borderColor: "red",
					mt: -0.5,
				}}
				direction="row"
			>
				<Grid
					item
					className={
						connectStatus === "connected"
							? "parameter-name-" + parm_name
							: "parameter-name-disconnected"
					}
				>
					{parm_name && t(parm_name)}
					<span
						style={{ marginLeft: 4 }}
						className={
							connectStatus === "connected"
								? "parameter-unit-" + parm_name
								: "parameter-unit-disconnected"
						}
					>
						{parm_unit}
					</span>
				</Grid>
				<Grid
					item
					xs={3}
					className={
						connectStatus === "connected"
							? "parameter-value-" + parm_name
							: "parameter-value-disconnected"
					}
					style={{ textAlign: "right" }}
				>
					<div
						className={
							active_alarm
								? "parameter-value-alarm"
								: "parameter-value-normal"
						}
					>
						{parm_val}
					</div>
				</Grid>
			</Grid>
		);
	}

	//console.log (parm_name, active_alarm)
	return (
		<Grid
			container
			direction="row"
			style={{ marginBottom: "1px", height: "100%" }}
		>
			{/* Parameter Name */}
			<Grid
				item
				xs={8}
				className={
					connectStatus === "connected"
						? "parameter-name-" + parm_name
						: "parameter-name-disconnected"
				}
			>
				{parm_name && t(parm_name)}

				<span
					className={
						connectStatus === "connected"
							? "parameter-unit-" + parm_name
							: "parameter-unit-disconnected"
					}
				>
					{" "}
					{parm_unit}
				</span>
			</Grid>

			{/* Parameter Value */}
			<Grid
				item
				xs={4}
				className={
					connectStatus === "connected"
						? "parameter-value-" + parm_name
						: "parameter-value-disconnected"
				}
				style={{ textAlign: "right" }}
			>
				<div
					className={
						active_alarm
							? "parameter-value-alarm"
							: "parameter-value-normal"
					}
				>
					{parm_val}
				</div>
			</Grid>
		</Grid>
	);
}; // ParmDisplay