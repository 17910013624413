import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
    activeAlarm?: boolean;
    backgroundColor?: string; // Optional background color prop
}

export const ParameterBox = ({ children, activeAlarm, backgroundColor }: Props) => {
    const height = "85px";

    return (
        <Grid
            className={activeAlarm ? "parameter-box-alarm" : "parameter-box-normal"}
            sx={{
                bgcolor: backgroundColor || "common.black", // Use provided background color or default to common.black
                minHeight: height,
                border: activeAlarm ? "solid 2px red" : "solid 1px white",
                padding: "3px",
            }}
            container
            direction="column"
            justifyContent="space-between"
        >
            {children}
        </Grid>
    );
};
